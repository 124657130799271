<template>
  <div v-if="show">
    <div v-if="props.title" class="block-title">{{ props.title }}</div>
    <v-lazy v-if="componentIsLazy">
      <UziQuery v-if="props.type === 'UziQuery'" v-bind="props.props" @nodata="nodata"></UziQuery>
      <Jackpots v-else-if="props.type === 'Jackpots'" v-bind="props.props" @nodata="nodata"></Jackpots>
    </v-lazy>
    <div v-else>
      <UziQuery v-if="props.type === 'UziQuery'" v-bind="props.props" @nodata="nodata"></UziQuery>
      <Jackpots v-else-if="props.type === 'Jackpots'" v-bind="props.props" @nodata="nodata"></Jackpots>
      <Passport v-else-if="props.type === 'Passport'" v-bind="props.props" @nodata="nodata"></Passport>
      <Footer v-else-if="props.type === 'Footer'" v-bind="props.props" @nodata="nodata"></Footer>
      <ImageBanner v-else-if="props.type === 'MainBanner'" v-bind="props.props"></ImageBanner>
      <PromoCategories v-else-if="props.type === 'PromoCategories'" v-bind="props.props"></PromoCategories>
      <Dumb v-else v-bind="props.props" :title="title"></Dumb>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(["nodata"]);
const props = defineProps({
  title: { type: String, default: "" },
  type: { type: String, required: true, default: "Dumb" },
  props: { type: Object, default: () => {} },
  id: { type: String, default: "" },
});
const page = inject("page");

const { type } = toRefs(props);
const show = ref(true);

const nodata = () => {
  show.value = false;
  emit("nodata");
};
const runtimeConfig = useRuntimeConfig();
const lazyComponents = runtimeConfig.app.lazyComponents;
const componentIsLazy = ref(lazyComponents.includes(type.value));
</script>
